// Finghway Colors

$white: #FFFFFF;
$white_opacity: #FFFFFF29;
$black1: #111111;
$black2: #2C323F;
$grey1: #707070;
$grey2: #00000029;
$grey3: #E6E9EB80;
$grey4: #F5F5F5;
$grey5: #FAFAFA;
$black_opacity: #00000015;
$orange1: #EC5200;
$orange2: #F46A20;
$orange3: #C05C27;
$orange1_opacity: #ec7535;
$orange2_opacity: #f46a209c;
$orange3_opacity: #F46A2029;
$red1: #B70606;
$red2: #ff0000;
$green1 : #06B76C;

// ---------------

$font-family-heading: 'Open Sans', sans-serif !important;
$font-family-base: 'Open Sans', sans-serif !important;
$font-size-base: 0.813rem;


$primary: #2D72FF !default;
$secondary: black !default;
$success: #10A200 !default;
$info: #FFF !default;
$warning: #FFF !default;
$danger: #FFF !default;
$light: #FFF !default;
$dark: #FFF !default;
$background: #FFF !default;
$foreground: #FFF !default;
$heading: #FFF;


$theme-colors: (
	primary: $primary,
	secondary: $secondary,
	success: $success,
	info: $info,
	warning: $warning,
	danger: $danger,
	light: $light,
) !default;

/**
BUTTONS
*/
$btn-primary-color: #FFF;
$btn-outline-secondary-color-hover: #FFF;
$btn-cancel-color: #FFF;
$btn-cancel-background-color: #FFF;
$btn-cancel-background-color-hover: #FFF;

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1170px
) !default;

// LAYOUT VARS
$topbar-height: 120px;
$topbar-height-mobile: 70px;
