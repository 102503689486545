@import 'variables';

html,
body {
	height: 100%;
	width: 100%;
	font-size: 16px;
	letter-spacing: 0.3px;
	line-height: 1.6;
	background: $white;
	font-family: 'Open Sans', sans-serif !important;

	scroll-behavior: smooth;
}

[tabindex='-1']:focus {
	outline: none;
}

hr {
	margin-top: 2rem;
	margin-bottom: 2rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	height: 0;
}

select,
button,
textarea,
input {
	vertical-align: baseline;
}
div {
	box-sizing: border-box;
}
html,
body {
	&[dir='rtl'],
	&[dir='ltr'] {
		unicode-bidi: embed;
	}
}
bdo[dir='rtl'] {
	direction: rtl;
	unicode-bidi: bidi-override;
}
bdo[dir='ltr'] {
	direction: ltr;
	unicode-bidi: bidi-override;
}

img {
	max-width: 100%;
}
a,
a:focus,
a:hover {
	text-decoration: none;
	cursor: pointer;
}
blockquote {
	border-left: 2px solid $grey4;
	padding-left: 1rem;
	margin-bottom: 1rem;
	font-size: 1.01625rem;
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
	outline: none;
}
.o-hidden {
	overflow: hidden;
}
.separator-breadcrumb {
	margin-bottom: 2rem;
}
@for $i from 1 through 5 {
	.line-height-#{$i} {
		line-height: #{$i};
	}
}

mat-form-field {
  width: 100%;
}

.mat-mdc-menu-panel {
  min-width: fit-content!important;
  max-width: none!important;
}

// Finghway

.button {
  padding: 0.5rem 1.2rem;
  white-space: nowrap;
  border-radius: 3px;
  border: none;

  &.orange {

    background-color: $orange1;
    color: $white;

    &:hover {
      color: $grey5;
      background-color: $orange1_opacity;
    }
  }

  &.cancel {
    color: $grey1;
    border: 1px solid;

    &:hover {
      background-color: $grey4;
    }
  }

}

#content {

  #containerTemplate {

    #container {

      .carton {
        background-color: $white;
        box-shadow: 0px 0px 10px $grey2;
        min-width: 18.75rem;
      }

      #componentHeaderTemplate {

        width: 100%;
        overflow-x: auto;
        min-height: 1.6rem;

        #componentHeader {
          display: flex;
          justify-content: space-between;
          min-width: fit-content;
          gap: 1rem;

          #options {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding-left: 0.5rem;

            #filter {
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1.5px solid $grey1;
              border-radius: 5px;
              cursor: pointer;

              i {
                color: $grey1;
                font-size: 1.3rem;
                padding: 0.5rem;
              }

              &:hover {
                i {
                  animation: pop 1.3s infinite;
                }
              }
            }

          }

        }

      }

      #componentTable {
        table {
          thead {
            background-color: $grey5;
            height: 2.5rem;
            th {
              height: 4rem;
              background-color: $grey5;

              div {
                display: flex;
                align-items: center;
                height: 100%;
                color: $black2;
                background-color: $grey5;
              }
            }

          }

          tbody {
            td {
              div {
                font-weight: 500;
              }

              #buttons {
                gap: 0.5rem;

                .button {
                  padding: 4px 5px;
                  background: $grey4;
                  border-radius: 5px;
                  border: 1px solid $grey4;

                  i {
                    color: $black2;
                  }
                }


              }

            }

          }

        }

      }

    }

  }

}

@keyframes pop {
  0%, 100% {
      transform: scale(0.9);
  }
  50% {
      transform: scale(1.05);
  }
}

#clearFilter {
  display: flex;
  width: 2.3rem;
  height: 2.3rem;
  min-width: 2.3rem;
  min-height: 2.3rem;
  justify-content: center;
  align-items: center;
  border: 1px solid #707070;
  border-radius: 5px;
  background-color: $grey4;
  font-size: larger;
  cursor: pointer;
  margin-right: 0.5rem;
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  padding: 1rem;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.3rem;
}

.exportButton {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 7rem;

  i {
    font-size: 1rem;
  }
}

.mat-mdc-dialog-surface {
  border-radius: 5px !important;
  box-shadow: 0px 0px 6px $grey2;

  #actionsDialog {
    display: flex;
    justify-content: space-between;
    align-items: end;

    #buttonsDialog {
      display: flex;
      justify-content: end;
      width: 100%;
      gap: 0.8rem;

      .button {
        min-width: 7rem;
        width: 100%;
      }
    }
  }
}

.mat-mdc-menu-content {
  padding: 0!important;
}
.mat-datepicker-content .mat-calendar{
  background-color: $white!important;
}

// Retirar após setar o tema do material

.mat-mdc-tooltip-surface.mdc-tooltip__surface {
  color: $white;
  background-color: $orange1_opacity;
}

.mat-mdc-menu-panel {
  background-color: $white !important;
}

.mat-mdc-paginator {
  background-color: transparent !important;
}
