@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');

@import 'variables';
@import 'globals';
@import 'ngx-toastr/toastr';

// Variable overrides first
$primary: #900;
$enable-shadows: true;
$prefix: "mo-";

// Then import Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap";
// @import 'bootstrap-rtl.scss';

.material-icons {
	font-family: 'Material Icons';
}

.color-primary {
  color: $primary;
}

table {
  td {
    vertical-align: middle;
  }
}

.table-line-hover {
  transition: .3s;
  &:hover {
    background: #e9e9e9;

    td {
      background: transparent;
    }
  }
}

/*SCROLL BAR*/

::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

/* Track */

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #a4a4a4;
}

::-webkit-scrollbar-track:hover {
  background: #555;
}
