// Variable overrides first
$primary: #900;
$enable-shadows: true;
$prefix: "mo-";

// Then import Bootstrap
@import "../node_modules/bootstrap/scss/bootstrap";

// Datetime Picker - components
@import "../node_modules/@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "./assets/scss/variables";
@import "../node_modules/@angular/material/prebuilt-themes/azure-blue.css";
// @import "./assets/scss/m3-theme.scss";


@import url('https://fonts.cdnfonts.com/css/myriad-pro');

html, body { height: 100%; }
body { margin: 0; font-family: "Open Sans", "Helvetica Neue", sans-serif!important; }


// v DatetimeRangePicker Component(start)
.owl-dt-calendar-table .owl-dt-calendar-cell-in-range {
  background: $orange1_opacity;
}

 .owl-dt-timer {
  outline: none;
  height: fit-content;
}

.owl-dt-timer-box {
  height: fit-content;
}

.owl-dt-timer-content {
  height: fit-content;

  &.owl-dt-timer-input {
    font-size: 1rem;
  }
}

.owl-dt-timer-divider {
  &:before {
    width: .25em;
    height: .25em;
  }
  &:after {
    width: .25em;
    height: .25em;
  }
}

.owl-dt-timer-divider {
  &:after {
    bottom: 38%
  }

  &:before {
    top: 38%
  }
}

.owl-dt-container-control-button {
  color: $orange1;
}

.owl-dt-container-info .owl-dt-container-info-active {
  color: $orange3;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: $orange1
}

.owl-dt-timer-hour12 .owl-dt-timer-hour12-box:focus .owl-dt-control-button-content,
.owl-dt-timer-hour12 .owl-dt-timer-hour12-box:hover .owl-dt-control-button-content {
  background: $orange1;
}

// ^ DatetimeRangePicker Component(end)
